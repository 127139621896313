// JavaScript Document
let save_to_local = false;
//let answers_for_score_feedback = new Array();
if ( 'undefined' != typeof( can_save ) && 0 == can_save['login_required'] && 0 == can_save['current_user_id'] ){ // login not required, we can save data to the local storage		
	save_to_local = true;
}

jQuery(document).ready(function($) {
	// Course and module page are hidden if JS is disabled. Display them if JS is enabled.
	$('.cpd.single-course').css('display','block'); 	
	$('.cpd.single-module-page').css('display','block'); 	

	if ( 'undefined' != typeof( can_save ) && '1' != can_save['can_save'] && '1' == can_save['login_required'] ){
		$( '#assets-form input, #assets-form textarea, #assets-form select ' ).prop( 'disabled', true );
	}

	//
	$('a').on('click',  function( evt ){		
		if( $( this ).attr('disabled') == true || $( this ).attr('disabled') == 'disabled' ){
			evt.preventDefault();
		}

	})
	$.fn.matchHeight._maintainScroll = true;
	
	$('#cpd-module-pages .lv1_url').on( 'mouseenter', function( evt ){
		if( $( this ).hasClass('current_parent') == false ){
			//$( this ).parent('.lv1').children( '.dropdown-menu' ).css('display', 'none');
		}
	});
	$('#cpd-module-pages .lv1_url').on('click', function( evt ){
		evt.preventDefault();
		$( this ).parent('.lv1').children( '.dropdown-menu' ).toggle('fast');
	});

	/** resize iframes start **/
	// Find all iframes
	var $iframes = $( "iframe" );
	 
	// Find &#x26; save the aspect ratio for all iframes
	$iframes.each(function () {
	  $( this ).data( "ratio", this.height / this.width )
		// Remove the hardcoded width &#x26; height attributes
		.removeAttr( "width" )
		.removeAttr( "height" );
	}); 
	 
	// Resize the iframes when the window is resized
	//$( window ).resize( function () {
	$( window ).on( 'resize', function () {	
	  $iframes.each( function() {
		// Get the parent container&#x27;s width
		var width = $( this ).parent().width();
		
		$( this ).width( width ).height( width * $( this ).data( "ratio" ) );
	  });
	// Resize to fix all iframes on page load.
	}).trigger('resize');


	/**
	 * hide and show assets hooked in interactive videos
	 */
	window.onresize = function(event) {
		on_page_resize();

	};	
	on_page_resize();
	/**
	 * update in video assets css
	 */
	function on_page_resize(){ 
		// if page pass, show them all at the original places
		
		if( pages_passed[0] == "0" ){
			
			if( $(window).width() < 975 ){
				
				$('.asset-content').each( function( idx, ele ) {
					if( $( this ).data('type') != 'Interactive Video'  ){
						
						var id_str = $( ele ).attr('id');
						var id_arr = id_str.split('-');
						var id_int = id_arr[2];
						// if this asset is hooked by an interactive video
						
						if( $.inArray( id_int.toString(), hooked_assets ) != -1 ){ 

							var child_ele = $('#asset-content-' + id_int + '').detach();
							
							$('#asset-box-' + id_int + '').append( child_ele );	
							//$('#asset-box-' + id_int + '').removeClass('hidden');		
							$('#asset-content-' + id_int + ' #iv-play-' + id_int + '').addClass('hidden');	
							//$( this ).removeAttr('style'); // It won't work if $('#asset-box-' + id_int + '') is hidden
							//$( this ).removeClass('pd2p asset-on-video'); //It won't work if $('#asset-box-' + id_int + '') is hidden
						}
					}
				});			
			} else {
				$('.asset-content').each( function( idx, ele ) {
					if( $( this ).data('type') != 'Interactive Video' ){				
						var id_str = $( ele ).attr('id');
						var id_arr = id_str.split('-');
						var id_int = id_arr[2];
						// if this asset is hooked by an interactive video
						if( $.inArray( id_int, hooked_assets ) != -1 ){ 					
							$('#asset-box-' + id_int + '').addClass('hidden');	
						}
					}
	
				});			
			}	
		}
	}
	/**
	 * Page passed enable the next button. A course doesn't have a next button
	 */ 
	if( pages_passed[0] == "1" && post_type != 'course' ){
		simpods_cpd_remove_disabled();
	}
	

	if(typeof $.fancybox == 'object' || typeof $.fancybox == 'function') { 
		/*
		 * Style fancybox caption 
		 * @link https://stackoverflow.com/questions/47716422/fancybox-caption-location
		 */
		$('[data-fancybox]').fancybox({
			
		    beforeShow: function() {
		        $('.caption--image').remove();
		    },
		    afterShow: function() {
		        var caption = $(".fancybox-caption"),
		            innerCaption = caption.clone().addClass('caption--image');

		        $(".fancybox-slide--current .fancybox-content").append(innerCaption);
		        caption.not('.caption--image').addClass('caption--bottom');
		    }
		});	
		// Fancybox Single Videos
		$('.interactive-video-fancybox').fancybox({
			
	        'autoDimensions': true,
	        'padding': 50,
	        'showCloseButton': true,
	        'enableEscapeButton': true,
	        'titlePosition': 'outside',
		    // afterLoad : function(instance, current) {

		    //     if ( screen_ratio > 1.5 ) {
		    //         current.width  = current.width  / screen_ratio;
		    //         current.height = current.height / screen_ratio;
		    //     }
		    // },        
	        beforeShow: function(){
				// only apply this to fancybox with interactive video
				$('.fancybox-slide').addClass('contain-interative-video');	
	        },
			afterShow: function() {

				//var current_video_wrap = $( this );
				$( this ).find('video').removeClass('hidden');
				
			  	// After the show-slide-animation has ended - play the video
			  	$( this ).find('video').trigger('play')
			  	// Trigger fancybox.close() once the video has ended
			  	$( this ).find('video').on('ended', function() {
			   // $.fancybox.close();
			  });
			},
			afterClose  : function() {
			    $('.fancybox-slide').removeClass('contain-interative-video');	
			    $( this ).find('video').addClass('hidden');
			}
		  
		});	
	}
	/**
	 * multi-choics and multi-answers on click
	 */
	$('.multi-choice input[type="radio"], .multi-answer input[type="checkbox"]').on('click', 
		function (){
			var input_name	= $( this ).prop('name');

			var input_type 	= $( this ).prop('type');
			var id 			= $( this ).attr('id');
			var value	 	= '';
			var checked_count = 0;  
			$.each( $('input[name="' + input_name + '"]:checked'), function(){
				if( $( this ).prop('id') != '' ){
					value = value + $( this ). val() + ',';
					checked_count ++;					
				}
				
			})
			// return false if exceeding max options allowed 

			if( typeof $('#' + input_name + '-parameters') != 'undefined' ){
				if( $('#' + input_name + '-parameters').data('max-options') != 'undefined' && $('#' + input_name + '-parameters').data('max-options') != 0 ){
					var max_options_allowed = parseInt( $('#' + input_name + '-parameters').data('max-options') );					
					if( checked_count > max_options_allowed ) {
						$('#' + input_name + '-parameters-info').fadeTo(200, 0.1).fadeTo(200, 1.0).fadeTo(200, 0.1).fadeTo(200, 1.0);
						return false;
					}
				}
			}

			if( value != '' ){
				value = value.substr( 0, value.length - 1 );	
			}

			//console.log( value );
			var int_value = $( this ).val();

			if ( value.includes( 'other' ) ){
				var values = {
					box : value,
					text : $( 'textarea[name="' + input_name + '"]' ).val()
				};
				with_options( input_name, values, int_value, input_type );
				
			} else {
				with_options( input_name, value, int_value, input_type );
			}

			simpods_cpd_toggle_inputs( true, id );
			
		}	
	)
	/**
	 * single drop down
	 */
	$('.dropdown select').on('change',
		function (){
			
			var input_name	= $( this ).prop('name');

			var input_type 	= 'select';
			
			var value	 	= '';
			value 			= $( this ). val() ;
			
			
			var int_value		= $( this ).val();
			with_options( input_name, value, int_value, input_type );
		}	
	)	
	/**
	 * multi drop down
	 */
	$('.multi-dropdown select').on('change',
		function (){
			
			var input_name	= $( this ).prop('name');

			var input_type 	= 'duplicate-select';
			var index 	= $( this ).data('idx');
			
			var value	 	= '';
			$.each( $('select[name="' + input_name + '"]'), function(){
				value = value + $( this ). val() + ',';
				
			})

			if( value != '' ){
				value = value.substr( 0, value.length - 1 );	
			}			
			var int_value	= $( this ).val();
			with_options( input_name, value, int_value, input_type, index );
		}	
	)	
	
	/**
	 * update feedback for assets with options
	 */
	function with_options( input_name, value, option_id, input_type, option_index ){
		var time_gap = simpods_cpd_calculate_time_gap();
		var data_obj 		  			= {};					
		var asset_id					= input_name.split('-')[1];	
		console.log('by with_options ')
		data_obj['action']	  			= 'update_asset_user_answer';
		data_obj[ input_name ]	  		= value;
		data_obj['set']	  				= record_set; 
		data_obj['security'] 			= cpd_ajax_script.nonce;
		data_obj['time_gap']			= time_gap;
		data_obj['decision_tree'] 		= '';
		data_obj['for_score_feedback'] 	= get_local_answers_for_score_feedback();
		if( typeof $('#asset-content-' + asset_id ).data('decision-tree') != 'undefined' ){
			data_obj['decision_tree'] = $('#asset-content-' + asset_id ).data('decision-tree');
		}

		if( typeof option_index == 'undefined' ){
			option_index = '';
		} 
		data_obj['option_index']	= option_index;
		
		$('#' + input_name + '-fb').html( '' ) ;
		$('#complete-txt').addClass('hidden');
		$('#' + input_name + '-load-img .loading' ).css('display','block');
		$('#' + input_name + '-' + option_index + '-fb').html( '' ) ;	

		// Load the local storage to process no matter login is required or not. We need it to
		//data_obj['local_storage'] = simpods_cpd_load_local_storage( data_obj );
		simpods_cpd_toggle_inputs( true );	
		$.post(
			cpd_ajax_script.ajaxurl,
			data_obj,
			function( return_obj ){	
				simpods_cpd_toggle_inputs( false );
				simpods_cpd_after_updating_user_answer( return_obj, data_obj, asset_id, input_name, value, option_id, input_type, option_index );
				// Data are saved to the local storage when login is not required. wp_strip_all_tags has been used to escape the data in the ajax
				simpods_cpd_save_to_local_storage( data_obj, return_obj );				
			}			
		)		
	}
	// We use this to help to determine when to trigger the saving
	var left_text_input 	= false;

	var current_asset_id 	= 0; // current open question id

	var past		= 0;	
	var now			= 0;	
	var is_key_up	= false;
	var input_type	= '';
	

	$( 'textarea, input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"]' ).on( 'mouseout', function() {
		if( is_key_up == true ){
			left_text_input = true;
		}
	} )
	// $( 'textarea, input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"]' ).on( 'keydown', function() {
	// 	//is_key_up = true;
	// 	left_text_input = false;
	// } )		

	// set interval to trigger saving every 1 second if keyup
	var saving_sil = setInterval(
		function(){ 			
			// only trigger if after stop typing for 1 second and is_key_up && 
			// Only trigger when leave an input box
			if( is_key_up && left_text_input ){
				
				if( now - past >= 1 ){
					if( input_type == 'open-ended-area' ){
						simpods_cpd_pass_asset( current_asset_id, $( 'textarea[name="asset-' + current_asset_id + '"]' ).val(), simpods_cpd_save_call_back );
					}
					if( input_type == 'open-ended-single' ){
						simpods_cpd_pass_asset( current_asset_id, $( 'input[name="asset-' + current_asset_id + '"]' ).val(), simpods_cpd_save_call_back );
					}
					if( input_type == 'grid-input' ){
						var answer_obj = {};
						$('#asset-content-' + current_asset_id + ' .grid-item-input').each( function( idx, ele ) {
							answer_obj[ $( this ).attr('name') ] = $( this ).val();
						});
						var answer = JSON.stringify( answer_obj ); ;
						simpods_cpd_pass_asset( current_asset_id, answer, simpods_cpd_save_call_back );
					}
					if( input_type == 'multi-choice' || input_type =='multi-answer' ){
						var value, answer_obj;

						$('input[name="asset-' + current_asset_id + '"][value="other"]' ).prop( 'checked', true ) ;																			
						value = '';
						var checked_count = 0;
						$.each( $('input[name="asset-' + current_asset_id + '"]:checked' ), function(){
							value = value + $( this ). val() + ',';
							checked_count ++;
						})
						
						// return false if exceeding max options allowed and uncheck others
						if( typeof $('#asset-' + current_asset_id + '-parameters') != 'undefined' ){
							if( $('#asset-' + current_asset_id + '-parameters').data('max-options') != 'undefined' ){
								var max_options_allowed = parseInt( $('#asset-' + current_asset_id + '-parameters').data('max-options') );
								if( 0 !== max_options_allowed && checked_count > max_options_allowed ) {
									$('#asset-' + current_asset_id + '-parameters-info').fadeTo(200, 0.1).fadeTo(200, 1.0).fadeTo(200, 0.1).fadeTo(200, 1.0);
									is_key_up	= false;	
									$( $('input[name="asset-' + current_asset_id + '"][value="other"]' ).prop( 'checked', false ) );	
								
								}
							}
						}													
						if( value != '' ){
							value = value.substr( 0, value.length - 1 );
						}

						answer_obj = {
							box  : value,
							text : $( 'textarea[name="asset-' + current_asset_id + '"]' ).val()
						};
						simpods_cpd_pass_asset( current_asset_id, answer_obj, simpods_cpd_save_call_back );
					}
					if( input_type == 'likert-scale' ){

						simpods_cpd_pass_asset( current_asset_id, Math.round( $( 'input[name="asset-' + current_asset_id + '"]' ).val() ), simpods_cpd_save_call_back );
					}
					left_text_input = false;
					is_key_up		= false;	
				}
				
			}
			now	++;	
		}, 
		1000 
	);	
	// Final submission of
	$(document.body).on('click', '.confirm-poll .submit-poll', function(){

		var asset_type = $( this ).data( 'asset-type' ).toLowerCase();

		if( asset_type == 'multi-choice' || asset_type == 'multi-answer' || asset_type == 'likert scale' ){
			var value_aggregated 	= '', 
				asset_id 		 	= $( this ).data( 'asset-id' ),
				answers;
			if( asset_type == 'multi-choice' || asset_type =='multi-answer' ){
				$.each( $('input[name="asset-' + asset_id + '"]:checked' ), function(){
					value_aggregated = value_aggregated + $( this ). val() + ',';
				} );
				if( value_aggregated != '' ){
					value_aggregated = value_aggregated.substr( 0, value_aggregated.length - 1 );
				}				
			}
			if( asset_type =='likert scale' ){
				
				value_aggregated = $('input[name="asset-' + asset_id + '"]' ).val();
			}

			answers  = {
				box  : value_aggregated,
				text : '',
				lock : 1,  // locked the button after submitting the poll
			};
			
			// only send over the text of "other" if the "other" option is checked
			if( $('input[name="asset-' + current_asset_id + '"][value="other"]:checked' ) ){
				answers.text = $( 'textarea[name="asset-' + asset_id + '"]' ).val();
			}
			if( value_aggregated != '' ){
				
				simpods_cpd_pass_asset( asset_id, answers, simpods_cpd_save_call_back );
			} else {
				$('#asset-box-' + asset_id + ' .asset-options').fadeTo(200, 0.1).fadeTo(200, 1.0).fadeTo(200, 0.1).fadeTo(200, 1.0);
			}
		}		
	});
	$('.open-ended, .open-ended-single, .grid-item-input, .other-input').on('keyup', function(){
		var id_int  = $( this ).data('id');		
		var id_str  = $( this ).attr('id');	
		current_asset_id = id_int;
		past	= now;		
		is_key_up	= true;
		//left_text_input = false;
		input_type	= $( this ).data('type');	
		$( '#asset-content-' + id_int + ' .save-open-ended' ).html('');
		$( this ).parent().children('.save-open-ended').removeClass('green');
		// load the next button in case it is on mobile and users go straight to the next button without triggering the saving
		simpods_cpd_add_disabled();
		simpods_cpd_toggle_inputs( true, id_str, id_int );
		
	});
	
	$('.asset-content textarea').on('keypress', function(){
		$( this ).parent().children('.save-open-ended').html('');		
		$( this ).parent().children('.save-open-ended').removeClass('green');
		var id_str  = $( this ).attr('id');
		var id_int  = $( this ).data('id');	
		simpods_cpd_toggle_inputs( true, id_str, id_int );
		
	})
	/**
	 * force numeric, number field only
	 */
	 $( 'input[type="number"]' ).on( 'mousewheel mouseup input', function (){
		//if( !$.isNumeric( $(this).val() ) ){
		if(	isNaN( $(this).val() ) ){ 
			$(this).val('');
			$(this).attr('placeholder', 'Number only')	; 
		}
		var id_int  = $( this ).data('id');		
		var id_str  = $( this ).attr('id');
		current_asset_id = id_int;
		past	= now;		
		is_key_up	= true;
		//left_text_input = false;
		input_type	= $( this ).data('type');	
		$( '#asset-content-' + id_int + ' .save-open-ended' ).html('');
		$( this ).parent().children('.save-open-ended').removeClass('green');	
		simpods_cpd_add_disabled();	
		simpods_cpd_toggle_inputs( true, id_str, id_int );
		
	 })

	$( 'input.likert-scale' ).on( 'change mousemove mouseup', function( event ){
		if($( this ).is(':disabled')){
	    	return false;
	    }
		var $this   = $( this ),
		    output  = $( 'output[for="' + $this.prop( 'id' ) + '"] .badge' ),
		    value   = Math.round( $this.val() ),
		    options = window['likert_scale_' + $this.data( 'id' ) + '_json'];
		    
		output.text( options[ value - 1 ] );
		//$output.css( 'left', 'calc( ' + ( value - 1 ) / ( $this.prop( 'max' ) - 1 ) * 100 + '% - ' + parseInt( $output.css( 'width' ) ) / 2 + 'px )' );
	
	} );

	$( 'input.likert-scale' ).on( 'change mouseup', function( event ){
		current_asset_id = $( this ).data('id');
		past    = now;
		is_key_up   = true;
		left_text_input = true;
		input_type    = $( this ).data('type');
		$( this ).val( Math.round( $( this ).val() ) );
		simpods_cpd_add_disabled();
		simpods_cpd_toggle_inputs( true, $( this ).prop( 'id' ), current_asset_id );
	} );

	$( '.file-upload' ).on( 'change', function( event ){
		var handler = $( this );
		simpods_cpd_upload_file( event, handler );
	} );

	
	$( '.delete-uploaded-file-btn' ).on( 'click', function( event ){

	    let can_upload = confirm( simpods_cpd_language['confirm_delete'] );
	            
	    if( ! can_upload ){
	    	return false;
	    }
		var file_obj   = event.target.files,
		    $this      = $( this ),
		    data_obj   = {},
		    prefix = '#asset-content-' + $this.data( 'id' );

		// Set action and add standard asset fields to FormData
		data_obj['action']	  		= 'cpd_delete_uploaded_file';		
		data_obj['set']	  			= record_set;
		data_obj['security'] 		= cpd_ajax_script.nonce;
		data_obj['asset'] 			= $this.data( 'id' );

		$( prefix + ' .loading' ).css( 'display', 'block' );
		$( prefix + ' .file-error' ).empty();
		$( prefix + ' .file-success' ).empty();

		$.post( {
			url         : cpd_ajax_script.ajaxurl,
			data        : data_obj,
		} ).done( function( res ){
			$( prefix + ' .file-preview' ).empty();

			$( prefix + ' .loading' ).css( 'display', 'none' );

			if ( res.success ){
				$('#asset-content-' + $this.data( 'id' ) + ' .btn-secondary' ).prop( 'disabled', true ); // doesn't work on <a> tag				
				$('#asset-content-' + $this.data( 'id' ) + ' a.btn-secondary' ).prop( 'href', '' ) ;
				$('#asset-content-' + $this.data( 'id' ) + ' a.btn-secondary' ).attr( 'disabled', true ); // work on <a> tag
				$('#asset-content-' + $this.data( 'id' ) + ' #file-upload-' + $this.data( 'id' ) ).parent('.btn-secondary').prop( 'disabled', false );		
				$('#asset-content-' + $this.data( 'id' ) + ' .file_upload_info' ).hide();		
			} else {
				$( prefix + ' .file-upload-name' ).empty();
				$( prefix + ' .file-error' ).text( res.data.error );
			}
		} ).fail( function( jqXHR, textStatus, errorThrown ){
			// Clear existing preview/name
			$( prefix + ' .file-preview' ).empty();
			$( prefix + ' .file-upload-name' ).empty();
			$( prefix + ' .loading' ).css( 'display', 'none' );

			// Process error message if any
			if ( 'undefined' != typeof jqXHR.responseJSON ){
				$( prefix + ' .file-error' ).text( jqXHR.responseJSON.data );
			} else {
				$( prefix + ' .file-error' ).text( 'Error communicating with server' );
			}
		} );

	} )	

	$( '.game .var-height' ).on('load', function() {
		$( this ).height( $( this ).contents().find( 'body' ).height() + 25 );
	});

	$( '.cpd .audio-btn' ).on( 'click', function( event ){
		event.preventDefault();

		let audio_elm = document.getElementById( $( this ).data( 'target' ) );

		if ( audio_elm.paused ){
			audio_elm.play();
		} else {
			audio_elm.pause();
		}
	} );
	$( '.cpd .simpods-cpd-transcript-btn' ).on( 'click', function( event ){
		event.preventDefault();

		var target = $( this ).data('target');

		$( target ).toggle('fast');
	} );

	$( window ).on( 'resize', function( event ){
		$( '.game .var-height' ).each( function() {
			$( this ).height( $( this ).contents().find( 'body' ).height() + 25 );
		});
	} );
	var enable_time_tracker = true;
	if( simpods_cpd_global['time_tracker'] != 'frequent' && ! ( simpods_cpd_global['time_tracker'] == 'frequent-desktop-only' && simpods_cpd_global['is_mobile'] == 0 ) ){
		enable_time_tracker = false;
	}
	/*
	 * Activity checking for timer
	 * Send a tick of the timer every 10 seconds 
	 * also every click will update the timer too
	 */	
	//var active_bln 	= true;

	/**
	 * Use user_interact and user_pre_interact to calculate the idle time
	 * If the course is focus and the session notices that there is no interaction for 10 minutes, 
	 * then the session will be deactivated until the user interact with the course again.
	 */
	var user_interact		= Math.round( new Date() / 1000);
	var user_prev_interact	= Math.round( new Date() / 1000);
	function timer_tick(){
		if ( document.hasFocus() ){
			//console.log(  ( parseInt( cur_time ) - parseInt( user_prev_interact ) ) );
		    // if the user is idle for over time minutes, do not send data
			if( ( parseInt( cur_time ) - parseInt( user_prev_interact ) ) > 600 ){
				 return;
			}
			add_update_timer();
			//active_bln = false;	
		}		

	}
	if( enable_time_tracker == false ){
		// every 60 seconds  
		setInterval( timer_tick, 10000 );
	}

	function add_update_timer( ) {

		if( enable_time_tracker == false ){
			return false; 
		}

		if ( 'undefined' != typeof( can_save ) && 1 != can_save['login_required'] && 0 == can_save['current_user_id'] ){ // no login required, don't need to track time spent			
			return;
		}

	    record_set['gap'] = 0;

		record_set['gap'] = simpods_cpd_calculate_time_gap();
		$.post(
			cpd_ajax_script.ajaxurl,
			{
				'action' : 'cpd_timer_tick',
				'nonce'  : cpd_ajax_script.nonce,
				'set'    : record_set,
					  
			}
		);	
		
	}

	$( window ).on('click', function( event  ){ // on clicking update timer. beforeunload doesn't always work	
		user_prev_interact 	= JSON.stringify( user_interact ); // JSON.stringify to copy the value not reference
		user_interact 		= Math.round( new Date() / 1000);
		add_update_timer();	 
		var element = event.target.nodeName;
		// allow users to keep typing in a text input
		if( is_key_up == true && element != 'TEXTAREA' && element != 'INPUT' ){
			left_text_input 	= true;
		}

	});

	/**
	 * timer: register the time when the page has been loaded. When the page is unload, work out the time
	 */

	$('.iframe-fullscreen-btn').on('click', function( evt ){
		var target_str = $( this ).data( 'target' );
		
		fullscreen_iframe( target_str );
	});

	/**
	 * open a iframe in full screen
	 * @param  string target_str the id of a iframe
	 * @return null
	 */
	function fullscreen_iframe( target_str ) {
		var iframe_ele = document.getElementById( target_str );
		
		if (iframe_ele.requestFullscreen) {
			iframe_ele.requestFullscreen();
		} else if (iframe_ele.mozRequestFullScreen) { /* Firefox */
			iframe_ele.mozRequestFullScreen();
		} else if (iframe_ele.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
			iframe_ele.webkitRequestFullscreen();
		} else if (iframe_ele.msRequestFullscreen) { /* IE/Edge */
			iframe_ele.msRequestFullscreen();
		}
	}

	/**
	 * toggle the indented submenus
	 */
	$(document.body).on('click', '.cpd-menu-toggle-indented', function( evt ){			
		var id 			= $( this ).data('id');
		var indented_lv = parseInt( $( this ).data('current-indented-lv') );	
		toggle_indented_submenus( $( this ), id, indented_lv);	
	});
	// Open the submenu of the current page
	var cur_indented_lv = parseInt( $('#cpd-module-page-' + simpods_module_page_params['current_page_id'] + '').data('indented-lv') );	
	// Open all the parents' submenus from the current page
	var onload_started 	= false;
	$( $('#cpd-module-pages .simpods-cpd-indented').get().reverse() ).each( function( idx, element ){	//bottom to top		
		
		var element_indented_lv = parseInt( $( this ).data('indented-lv') );
		// re-open those indented levels that bigger than the clicked one
		if( onload_started === true ){ // check anyone after the clicked one to see if its indented level is 1 greater the the clicked one. If yes, re-open it
			
			if( element_indented_lv < cur_indented_lv ){
				
				if( $('#cpd-module-page-' + $( this ).data('id') + ' .cpd-menu-toggle-indented').length != 0 ){
					//$('#cpd-module-page-' + $( this ).data('id') + ' .cpd-menu-toggle-indented').click();	
					$('#cpd-module-page-' + $( this ).data('id') + ' .cpd-menu-toggle-indented').trigger('click');	
				}
			} 
		}	
		if( element_indented_lv == 0  ) { // back to lv 0
			onload_started = false;	
		}						
		if( $( this ).data('id') == simpods_module_page_params['current_page_id'] ){
			onload_started = true;
			//$( this ).show('fast');
		}
		
	});	
	

	function toggle_indented_submenus( clicked_btn, id, indented_lv){

		// $('#cpd-module-pages .simpods-cpd-indented').each( function( idx, element ){
		// 	// close all child items first
		// 	if( $( this ).data('indented-lv') != '0' && id !=  $( this ).data('id')  ){
		// 		$( this ).hide();
		// 	};			
		// });
		
		var started 	= false;
		var found 		= false; // stop it from open and then close immediately
		// Open the submenu
		if( clicked_btn.hasClass( 'cpd-child-menu-hidden' ) ){
		
			started 	= false;
			$('#cpd-module-pages .simpods-cpd-indented').each( function( idx, element ){			
				var element_indented_lv = parseInt( $( this ).data('indented-lv') );
				// re-open those indented levels that bigger than the clicked one
				if( started === true ){ // check anyone after the clicked one to see if its indented level is 1 greater the the clicked one. If yes, re-open it
					if( element_indented_lv == indented_lv + 1 ){
						$( this ).show('fast');
					} 
				}	
				if( element_indented_lv == indented_lv  ) { // meet one with the same indented level, stop.
					started = false;			
				}						
				if( id ==  $( this ).data('id') ){ // locate the clicked one
					started = true;
					$( this ).show('fast');
				}
			});	
			clicked_btn.removeClass( 'cpd-child-menu-hidden' );
			clicked_btn.addClass( 'cpd-child-menu-shown' );
			clicked_btn.html( '<span class="fas fa-angle-up"></span>' );
			found 		= true;
		} 
		// close the submenu
		if( found === false ){
			
			started 	= false;
			$('#cpd-module-pages .simpods-cpd-indented').each( function( idx, element ){			
				var element_indented_lv = parseInt( $( this ).data('indented-lv') );
				// re-open those indented levels that bigger than the clicked one
				if( started === true ){ // check anyone after the clicked one to see if its indented level is greater the the clicked one. If yes, hide it
					if( element_indented_lv > indented_lv ){
						$( this ).hide('fast');
						$( this ).children('.cpd-menu-toggle-indented').addClass( 'cpd-child-menu-hidden' );
						$( this ).children('.cpd-menu-toggle-indented').removeClass( 'cpd-child-menu-shown' );
						$( this ).children('.cpd-menu-toggle-indented').html( '<span class="fas fa-angle-down"></span>' );						
					} 
				}	
				if( element_indented_lv == indented_lv  ) { // meet one with the same indented level, stop.
					started = false;				
				}						
				if( id ==  $( this ).data('id') ){ // locate the clicked one
					started = true;					
				}
			});	
			clicked_btn.addClass( 'cpd-child-menu-hidden' );
			clicked_btn.removeClass( 'cpd-child-menu-shown' );
			clicked_btn.html( '<span class="fas fa-angle-down"></span>' );
		}

	}
	/**
	 * Disable right click
	 */
	// if( typeof simpods_module_page_params['disable_right_click'] != 'undefined' ){
	// 	if( simpods_module_page_params['disable_right_click'] == 1 ){
	// 		document.addEventListener('contextmenu', event => event.preventDefault());
	// 	}
	// }
	$('.interactive-video-only').on('contextmenu', function(e) {
	    return false;
	}); 
	/**
     * Submit for completion
     */
	$('.completion-trigger-btn').on('click', function( evt ){
		evt.preventDefault();
		$('#simpods-cpd-confirm-modal').modal('show');
		$('#simpods-cpd-confirm-modal-btn-yes').data('asset-id', $( this ) .data( 'asset-id' ) );
		$('#simpods-cpd-confirm-modal .level').text( $( this ) .data( 'level' ).toLowerCase() );
	});
	$('#simpods-cpd-confirm-modal-btn-yes').on('click', function( evt ){
		evt.preventDefault();
		
		var asset_id = $('#simpods-cpd-confirm-modal-btn-yes').data('asset-id');
		
		$('#simpods-cpd-confirm-modal').modal('hide');
		trigger_completion( asset_id )
	});

	function trigger_completion( asset_id ){
		var data_obj   	= {},
		    prefix 		= '#asset-content-' + asset_id;
		   // asset_id 	= $this.data( 'asset-id' );

		// Set action and add standard asset fields to FormData
		data_obj['action']	  		= 'submit_for_completion';		
		data_obj['set']	  			= record_set;
		data_obj['security'] 		= cpd_ajax_script.nonce;
		data_obj['asset'] 			= asset_id;

		$( prefix + ' .loading' ).css( 'display', 'block' );

		$.post( {
			url         : cpd_ajax_script.ajaxurl,
			data        : data_obj,
		} ).done( function( res ){
			//$( prefix + ' .loading' ).css( 'display', 'none' );
			$('#asset-' + asset_id + '-fb' ).removeClass('hidden');		
			if ( res.success ){
				$( prefix + ' form' ).submit();
			} else {
				$( prefix + ' .file-upload-name' ).empty();
				$('#asset-' + asset_id + '-fb' ).text( res.data.error );
			}
		} ).fail( function( jqXHR, textStatus, errorThrown ){
			// Clear existing preview/name
			$( prefix + ' .loading' ).css( 'display', 'none' );
			// Process error message if any
			if ( 'undefined' != typeof jqXHR.responseJSON ){
				$('#asset-' + asset_id + '-fb' ).text( jqXHR.responseJSON.data );
			} else {
				$('#asset-' + asset_id + '-fb' ).text( 'Error communicating with server' );
			}
		} );

	}	
	// select 2 only loaded when some assets like Multi-Dropdown is available	
	try {
	   // $('#simpods-cpd-body .select2').select2( { width: '100%' } );
	} catch (err) {

	}
	/**
	 * Toggle the icons on accordion
	 */ 
	$('.panel-collapse').on('show.bs.collapse', function () {
		var id = $( this ).prop('id') ;
	  	$( '#' + id + '-link' ).find('.simpods-cpd-in-body-icon-btn').removeClass('fa-folder');
	  	$( '#' + id + '-link' ).find('.simpods-cpd-in-body-icon-btn').addClass('fa-folder-open');
	})
	$('.panel-collapse').on('hidden.bs.collapse', function () {
		var id = $( this ).prop('id') ;
	  	$( '#' + id + '-link' ).find('.simpods-cpd-in-body-icon-btn').addClass('fa-folder');
	  	$( '#' + id + '-link' ).find('.simpods-cpd-in-body-icon-btn').removeClass('fa-folder-open');
	})	

	/***
	 * decision trees
	 */ 
	$( '.redo-decision-tree-btn' ).on( 'click', function(){

		var asset_id 	= $( this ).data( 'asset-id' );
		var data_obj   	= {},
		    prefix 		= '#asset-content-' + asset_id;
		   // asset_id 	= $this.data( 'asset-id' );

		// Set action and add standard asset fields to FormData
		data_obj['action']	  		= 'restart_decision_tree';		
		data_obj['set']	  			= record_set;
		data_obj['security'] 		= cpd_ajax_script.nonce;
		data_obj['asset'] 			= asset_id;

		$( prefix + ' .loading' ).css( 'display', 'block' );

		$.post( {
			url         : cpd_ajax_script.ajaxurl,
			data        : data_obj,
		} ).done( function( res ){
			$( prefix + ' .loading' ).css( 'display', 'none' );
			$('#asset-content-' + asset_id + ' .light-bulb').removeClass('light-on');	
			$('#asset-content-' + asset_id + ' .decision-tree-completed-flag').addClass('opacity-30');
			//uncheck all multi-choices in the decision tree
			$('.asset-content[data-decision-tree="'+ asset_id + '"] input').prop('checked', false); 
			//hide all and only show the first one
			$('.asset-content[data-decision-tree="'+ asset_id + '"]').parent().addClass('hidden'); 
			$('.asset-content[data-decision-tree="'+ asset_id + '"]').parent().find('.feedback').html(''); 
			$('.asset-content[data-decision-tree="'+ asset_id + '"]').first().parent().removeClass('hidden'); 
		
		} ).fail( function( jqXHR, textStatus, errorThrown ){
			// // Clear existing preview/name
			$( prefix + ' .loading' ).css( 'display', 'none' );

		} );

	});
	$('.decision-tree-completed-flag').each( function( idx, element ){			

		var branch_ids 			= $( this ).data('branch-ids');
		var displayed_branch_id = $( this ).data('displayed-branch-id');

		branch_ids = branch_ids.split(',');
		
		for( var i = 0; i < branch_ids.length; i ++ ){
			$( '#asset-box-' + branch_ids[ i ] ).addClass( 'hidden' );
		}

		$( '#asset-box-' + displayed_branch_id ).removeClass( 'hidden' );
			
		
	} );

	/**
	 * Take an item and show the items with the same id one after another
	 */ 
	function simpods_cpd_fades( previous_item, interval ) {		
		previous_item.hide();
    	previous_item.css('visibility', 'visible');		
	    previous_item.fadeIn( interval, function () {
	        previous_item.fadeIn( interval, function () {
	            var next_item = previous_item.next();
	            
	            if ( next_item.length > 0 ) {
	                simpods_cpd_fades( next_item );
	            }

	        });
	    });
	}
	$('.simpods-cpd-items-one-by-one').each( function( idx, element ){			
		var id 			= $( this ).prop('id');
		var target 		= $( this ).data('target');
		var interval 	= $( this ).data('interval');
		simpods_cpd_fades( $('#' + id + ' ' + target + ':first' ), interval );		
	} )

	/**
	 * Stop the audio playing when the preview modal is closed.
	 */ 
	$('.simpods-cpd-file-upload-modal .close').on('click', function( event ) {

		var asset_id = $( this ).data('asset-id'); 
		$('#simpods-cpd-file-upload-' +  asset_id + '-modal audio').each( function(){
		    this.pause(); // Stop playing
		    this.currentTime = 0; // Reset time
		}); 
		$('#simpods-cpd-file-upload-' +  asset_id + '-modal video').each( function(){
		    this.pause(); // Stop playing
		    this.currentTime = 0; // Reset time
		}); 		
	})	 

	$('.download-certificate').on('click', function( event ) {
		event.preventDefault();
		var	asset_id 		= $( this ).data( 'asset-id' ),
			answers			= 1;		
		simpods_cpd_pass_asset( asset_id, answers, simpods_cpd_save_call_back );
	})	
	$('.export-answers').on('click', function( event ) {
		var holder = $( this ).parent();

		holder.find('.loading').show();
		// when the page is out of focus, hide the loading gif
		$( 'body' ).on( 'focusout', function() {		   

		    holder.find('.loading').hide();
		});		
	})	

	/**
	 * Process assets onload, pass them in as a preliminary group
	 */
	if( to_process_onload.length != 0 ){ 
		var for_passing_assets = {
			'group_to_process' : to_process_onload
		};
		simpods_cpd_pass_asset( 0, '', '', '', for_passing_assets ); 
	}
	/**
	 * Toggle the asset content when a background is added.
	 */ 
	$('.asset-content-toggle').on('click', function(){
		var asset_id = $( this ).data('asset-id');
		if( $(this).children('.fas').hasClass('blue') ){
			$('#asset-content-' + asset_id ).hide('fast');
			$(this).children('.fas').removeClass('blue');
			$(this).children('.fas').removeClass('fa-toggle-on');
			$(this).children('.fas').addClass('fa-toggle-off');
			if( $( '#asset-box-background-' + asset_id ).children( 'video' ).length > 0 ){
				$( '#asset-box-background-' + asset_id + ' video' ).trigger('play');
			}
		} else {
			$('#asset-content-' + asset_id ).show('fast');
			$(this).children('.fas').addClass('blue');
			$(this).children('.fas').removeClass('fa-toggle-off');
			$(this).children('.fas').addClass('fa-toggle-on');
			if( $( '#asset-box-background-' + asset_id ).children( 'video' ).length > 0 ){
				$( '#asset-box-background-' + asset_id + ' video' ).trigger('pause');
			}
		}
	}) 

	//populate from locate storage
	function populate_asset_values(){
		let args = {
			set : record_set,
			asset_ids : new Array(),
		}
		
		$('#assets-form input').each(function( element ) {
		    args.asset_ids = simpods_cpd_get_asset_id_from_input( $( this ).attr('name'), args.asset_ids );
		});
		$('#assets-form textarea').each(function() {
		    args.asset_ids = simpods_cpd_get_asset_id_from_input( $( this ).attr('name'), args.asset_ids );
		});
		$('#assets-form select').each(function() {
		    args.asset_ids = simpods_cpd_get_asset_id_from_input( $( this ).attr('name'), args.asset_ids );
		});


		args.asset_ids = [...new Set( args.asset_ids )]; // remove duplicate
		
		let answers = simpods_cpd_load_local_storage( args ) ;
		
		for( let input_name in  answers ){
			input_name = simpods_cpd_esc_string( input_name ); 
			
			if( typeof answers[ input_name ]['answer'] != 'object' ){
				answers[ input_name ]['answer'] = simpods_cpd_esc_string( answers[ input_name ]['answer'] ); 
				
				if( $('input[name="' + input_name + '"]').length != 0 ){

					let values = answers[ input_name ]['answer'].split(',');//
					if( values.length > 1 ){ //checkbox
						for( let value_key in values ){
							value_key 			= simpods_cpd_esc_string( value_key ); 
							values[ value_key ] = simpods_cpd_esc_string( values[ value_key ] ); 
							$('input[name="' + input_name + '"][value ="' + values[ value_key ] + '"]').prop('checked', true);
						}
					} else {
						if( $('input[name="' + input_name + '"][value ="' + answers[ input_name ]['answer'] + '"]').length != 0 ){ //radio, checkbox
							$('input[name="' + input_name + '"][value ="' + answers[ input_name ]['answer'] + '"]').prop('checked', true);
						} else {
							// range (likert scale)
							$('input[name="' + input_name + '"]' ).val( answers[ input_name ]['answer'] );
						}
					}
				} else if( $('select[name="' + input_name + '"]').length != 0 ){
					let values = answers[ input_name ]['answer'].split(',');

					if( values.length > 1 ){ // multidrop down
						for( let value_key in values ){
							// somehow not working to all multi-dropdowns. Select 2 could be the problem.
							value_key 			= simpods_cpd_esc_string( value_key ); 
							values[ value_key ] = simpods_cpd_esc_string( values[ value_key ] ); 
														
							$('select[name="' + input_name + '"][data-idx="' + value_key + '"]').val( values[ value_key ] );
							//$('select[name="' + input_name + '"][data-idx="' + value_key + '"]').select2('val', values[ value_key ] );
							
						}
					} else {
						$('select[name="' + input_name + '"]').val( answers[ input_name ]['answer'] );
						//$('select[name="' + input_name + '"]').select2('val', answers[ input_name ]['answer']);
					}
				} else if( $('textarea[name="' + input_name + '"]').length != 0 ){
					$('textarea[name="' + input_name + '"]').val( answers[ input_name ]['answer'] );
				} else {

				}
			} else {				
				for( let key in answers[ input_name ]['answer'] ){
					key = simpods_cpd_esc_string( key ); 
					answers[ input_name ]['answer'][ key ] = simpods_cpd_esc_string( answers[ input_name ]['answer'][ key ] ); 
					
					if( key == 'box' ){
						let box_values = answers[ input_name ]['answer'][ key ].split(',');
						for( let box_key in box_values ){
							box_values[ box_key ] = simpods_cpd_esc_string( box_values[ box_key ] ); 								
							$('input[name="' + input_name + '"][value ="' + box_values[ box_key ] + '"]').prop('checked', true);
							
						}
					}
					if( key == 'text' ){
						$('textarea[name="' + input_name + '"]').val( answers[ input_name ]['answer'][ key ] );
					}
					
					// fill in the blanks
					if( ! isNaN( key ) ){

						if( $('input[name="' + input_name + '"]').eq( key ).length != 0 ){
							$('input[name="' + input_name + '"]').eq( key ).val( answers[ input_name ]['answer'][ key ] );
						}
						//console.log($('select[name="' + input_name + '"]').eq( key ));
						if( answers[ input_name ]['answer'][ key ].indexOf('|') == -1 ){ // not sorted into groups
							if( $('select[name="' + input_name + '"]').eq( key ).length != 0 ){
								$('select[name="' + input_name + '"]').eq( key ).val( answers[ input_name ]['answer'][ key ] );
							}
						} else {
							let grouped_answers = answers[ input_name ]['answer'][ key ].split('|');

							if( $('select[name="' + input_name + '"]').eq( key ).data('group') == grouped_answers[1] ){
								$('select[name="' + input_name + '"]').eq( key ).val( grouped_answers[0] );
							}							
						}
					}
					
					//grid inputs
					$('input[name="' + key + '"]').val( answers[ input_name ]['answer'][ key ] );
					$('textarea[name="' + key + '"]').val( answers[ input_name ]['answer'][ key ] );
				
				}
			}
		}

	}
	// only do it when login is not required
	if( save_to_local ){
		populate_asset_values();
	}
	get_local_answers_for_score_feedback();
});	

function get_local_answers_for_score_feedback(){
	// Score and feedback
	let score_feedback_args = {
		set : record_set,
		asset_ids : new Array(),
	}

	jQuery('.score-feedback-asset-ids').each( function( idx, element ){
		let score_feedback_asset_ids = jQuery( this ).data( 'asset-ids' );
		if( score_feedback_asset_ids != '' ){
			score_feedback_asset_ids = score_feedback_asset_ids.split(',');
		}
		score_feedback_args.asset_ids = score_feedback_args.asset_ids.concat( score_feedback_asset_ids );
	} );
	score_feedback_args.asset_ids = [...new Set( score_feedback_args.asset_ids )]; // remove duplicate

	return simpods_cpd_load_local_storage( score_feedback_args ) ;	
}

function simpods_cpd_get_asset_id_from_input( name = '', ids ){

	
	let asset_name_pieces	= name.split('-');	
	if( asset_name_pieces.length < 2 || asset_name_pieces[0] != 'asset' ){
		return ids;
	}
	let asset_id = asset_name_pieces[1];
	
	if( typeof ids == 'object' ){		
	    if( ! isNaN( asset_id ) ){	    	
	    	ids.push( parseInt( asset_id ) );
	    }		    
	}
	return ids;	

}
var cur_time 		= Math.round( new Date() / 1000);
function simpods_cpd_calculate_time_gap(){
    var time_gap = Math.round(new Date() / 1000) - cur_time;
    cur_time = Math.round(new Date() / 1000);

     // minimum register time gap, 1 minutes to avoid overloading the server, in case someone keep clicking the page  
    if( time_gap < 5 ) {     
      return 0;
    }
    // if idle for more than 10 minutes, limit it to 10 minutes  
    if( time_gap > 600 ) {
        time_gap = 600;
    }

    return time_gap;
}
/**
* disable/enable all inputs
*/
function simpods_cpd_toggle_inputs( off_bln, skip_id, asset_id, to_skip_enable, call_back ){
	var types 	=	['input', 'select', 'textarea', 'button'];

	for( var i = 0; i < types.length; i ++ ){
		
		if( off_bln == true ){
			var elements	=	jQuery( '#assets-form ' + types[ i ] );
			if( elements.length > 0 ){
				for( var j = 0; j < elements.length; j ++ ){
					if( jQuery( elements[ j ] ).attr('id') != skip_id ){
						jQuery( elements[ j ] ).parent( '.cpd-input-wrap' ).css('opacity', '0.3');	
						jQuery( elements[ j ] ).attr( 'disabled', 'disabled' );
					} else {
						jQuery( elements[ j ] ).parent( '.cpd-input-wrap' ).css('opacity', '1');
					}

				}
			} 
		} else {
			jQuery('.cpd-input-wrap').css('opacity', '1');
			jQuery('#assets-form ' + types[ i ] ).removeAttr( 'disabled' );
			if( to_skip_enable ){
				jQuery( to_skip_enable ).attr( 'disabled', 'disabled' );
			}
		}
	}
	// Disable all locked items again
	jQuery('.asset-parameters').each( function( idx, element ){			

		var asset_id 	= jQuery( this ).data('asset-id');
		var locked 		= jQuery( this ).data('locked');
		if( locked == 1 ){ 
			for( var i = 0; i < types.length; i ++ ){
				jQuery( '#asset-box-' + asset_id + ' ' + types[ i ] ).prop( 'disabled', 'disabled' );

			}
			jQuery( '#asset-box-' + asset_id + ' .view-poll-results' ).removeAttr( 'disabled' );
			
		}
	} );
	if( Number.isInteger( asset_id ) ){
		jQuery( '#asset-content-' + asset_id + ' #asset-' + asset_id + '-fb' ).html('');
	}
	if( call_back ) {
		call_back( off_bln, skip_id, asset_id, to_skip_enable );		
	}
}
/**
 * save asset, for games as well
 */
function simpods_cpd_pass_asset( asset_id, answer, callBack, append_answer = [], attrs = {}, to_skip_enable, toggle_inputs_call_back ){
	//if( jQuery.isNumeric( asset_id ) ){
	console.log('by simpods_cpd_pass_asset ')
	var time_gap = simpods_cpd_calculate_time_gap();
	
	if( ! isNaN( asset_id ) ){
		
		if( typeof answer == 'undefined' ){
			answer = 1;	
		}
		
		var input_name 						= 'asset-' + asset_id;
		var data_obj 		  				= {};	
		data_obj['action']	  				= 'update_asset_user_answer';
		data_obj['asset-' + asset_id ] 		= answer;
		data_obj['set']	  					= record_set; 
		data_obj['security'] 				= cpd_ajax_script.nonce;
		data_obj['append_answer']			= append_answer;
		data_obj['attrs']					= attrs;
		data_obj['time_gap']				= time_gap;
		data_obj['for_score_feedback'] 		= get_local_answers_for_score_feedback();
		jQuery( '#asset-' + asset_id + '-load-img .loading' ).css('display','block');	
		if( asset_id != 0 ){ //0 means processing a group of assets in the attrs after the page is loaded. No need to lock the inputs as it is done clandestinely
			simpods_cpd_toggle_inputs( true );
		}
		var start_time 						= new Date().getTime();
		// Load the local storage to process no matter login is required or not. We need it to
		// simpods_cpd_load_local_storage( data_obj );		
		jQuery.post(
			cpd_ajax_script.ajaxurl,
			data_obj,
			function( return_obj ){	
				simpods_cpd_toggle_inputs( false, '', '', to_skip_enable, toggle_inputs_call_back );
				simpods_cpd_after_updating_user_answer( return_obj, data_obj, asset_id, input_name, answer, 0, '', '', callBack );
				
				// Data are saved to the local storage when login is not required. wp_strip_all_tags has been used to escape the data in the ajax
				simpods_cpd_save_to_local_storage( data_obj, return_obj );			
				var request_time = new Date().getTime() - start_time;
				console.log( 'Request time: ' + request_time );					
			}
		)
	
	}

}

function simpods_cpd_play_sound( sound_name ){
	if( typeof sound_name != 'undefined' && sound_name.length != 0 ){ //play sound
		var sound = document.getElementById('simpods-cpd-audio-' + simpods_cpd_esc_string( sound_name ) );
		sound.play();
	}
}
/**
 * get user answer
 */

function simpods_cpd_get_user_answer( assetID_int ){

	//if( jQuery.isNumeric( assetID_int ) ){
	if( ! isNaN( assetID_int ) ){	
		var data_obj 		  				= {};	
		data_obj['action']	  				= 'cpd_fetch_user_answer';		
		data_obj['set']	  					= record_set;
		data_obj['set']['asset_id']			= assetID_int;
		data_obj['security'] 				= cpd_ajax_script.nonce;
		jQuery( '#asset-' + assetID_int + '-load-img .loading' ).css('display','block');	
		//simpods_cpd_toggle_inputs( true );
		jQuery.post(
			cpd_ajax_script.ajaxurl,
			data_obj,
			function( return_obj ){	
				
				jQuery( '#asset-' + assetID_int + '-load-img .loading' ).css('display','none');
				if ( return_obj.success ){
					//game_answers[ 'asset_' + asrecord_set[1] ]
					//console.log( return_obj );
				}
				jQuery.fn.matchHeight._update();
			}
		)
	}

}

/**
 * Display feedback info after an asset is submitted.
 */ 
function simpods_cpd_save_call_back( id, return_data ){

	var feedback = ''; // preset feedback like the positive and negative
	var feedback_html = '';
	var custom_feedback = ''; // dynamically work out by the programme
	var custom_feedback_html = '';
	var i = 0;

	var html = '<span class="feedback-icon fa fa-floppy-o far fa-save mgr5" aria-hidden="true"></span><span class="answer-fb saved"> <span class="custom-feedback"></span><span class="preset-feedback"> Saved </span></span>';
	//return_data['has_correct'] == true &&;
		

	for( i = 0; i < return_data.asset_done.length; i ++ ){

		if( parseInt( return_data.asset_done[ i ]['id'] ) == parseInt( id ) ){
			custom_feedback = '';
			if( typeof return_data.asset_done[ i ]['custom_feedback'] != 'undefined' ){
				custom_feedback = return_data.asset_done[ i ]['custom_feedback'];	
			}
			custom_feedback_html = '<span class="custom-feedback">' + custom_feedback + '</span>';
			
			if( return_data.asset_done[ i ]['pass'] == 1 ){	
				feedback_html =  '<span class="feedback-icon fas fa-check green" aria-hidden="true"></span><span class="answer-fb correct"><span class="custom-feedback">' + custom_feedback_html + '</span><span class="preset-feedback">' + return_data.asset_done[ i ]['feedback'] + '</span></span>';

			} else {
				feedback_html =  '<span class="feedback-icon fas fa-times red" aria-hidden="true"></span><span class="answer-fb incorrect"><span class="custom-feedback">' + custom_feedback_html + '</span><span class="preset-feedback">' + return_data.asset_done[ i ]['feedback'] + '</span></span>';
			}	
			feedback = return_data.asset_done[ i ]['feedback'];	
		}			
	}
	// Display feedback if the feedback is not empty, otherwise, display "Saved"
	if( return_data['hide_feedback'] == '0' && ( custom_feedback != '' || feedback != '' ) ){			
		html = feedback_html;
	}
	// overwrite above if it is a poll
	if( typeof return_data['poll_type'] != 'undefined' && return_data['poll_type'] != '' ){
		if ( 'undefined' == typeof( can_save ) || 1 == can_save['login_required'] || 0 != can_save['current_user_id'] ){ // login required, don't display the submit button
			html = feedback_htmls['ready_to_submit'] ;		
		}
		
	} 		

	jQuery( '#asset-content-' + id + ' #asset-' + id + '-fb' ).html( html );
}

function simpods_cpd_remove_disabled(){

	if( typeof post_type != 'undefined' ){
		
		jQuery('.module-btn').attr('disabled', false );
		
		jQuery('.module-btn').each( function( idx ){
			
			if( jQuery( this ).children('.fa-unlock').length !== 0 ){
				
				jQuery( this ).children('.fa-lock').addClass('fa-unlock');
				jQuery( this ).children('.fa-lock').removeClass('fa-lock');
			}
		} ) ;

		jQuery('#simpods-cpd-body .simpods-cpd-icon-btn').attr('disabled', false );		
		
		jQuery('#simpods-cpd-body .module-btn').closest('.simpods-cpd-info-modal-toggle').removeClass('simpods-cpd-info-modal-toggle');
		//module page

		if( post_type[0] == 'module-page' ){
			// to ensure "disable" can be removed.
			jQuery('#simpods-cpd-body #next-page-btn').attr('disabled', false );	
			jQuery('#simpods-cpd-body #next-page-btn').prop('disabled', false );	
			jQuery('#simpods-cpd-body #next-page-btn').removeAttr( 'disabled' );

			jQuery('#simpods-cpd-body #next-page-btn').removeClass('simpods-cpd-info-modal-toggle');
			jQuery('#simpods-cpd-body #next-page-btn').removeAttr( 'data-toggle' );
			
			// Fix the problem that the button become unclickable even its disable attribute is removed
			var clone = jQuery( '#next-page-btn' ).clone();
			jQuery( '#next-page-btn' ).remove();
			clone.appendTo( "#next-btn-div" );
		}
	}	
}
/**
 * Add the disable attribute to buttons
 */ 
function simpods_cpd_add_disabled(){

	if( simpods_cpd_global['current_post_type'] == 'course' ){
		jQuery('.module-btn').attr('disabled', true );
		jQuery('.module-btn').each( function( idx ){
			
			if( jQuery( this ).children('.fa-unlock').length !== 0 ){
				
				jQuery( this ).children('.fa-unlock').addClass('fa-lock');
				jQuery( this ).children('.fa-unlock').removeClass('fa-unlock');
			}
		} ) ;
		//jQuery('#simpods-cpd-body .simpods-cpd-icon-btn').prop('disabled', true);
		jQuery('#simpods-cpd-body .simpods-cpd-icon-btn').attr('disabled', true );		
		jQuery('#simpods-cpd-body .module-btn').closest('.simpods-cpd-info-modal-toggle').addClass('simpods-cpd-info-modal-toggle');
	}
			//module page
	if( simpods_cpd_global['current_post_type'] == 'module-page' ){
		//jQuery('#simpods-cpd-body #next-page-btn').prop('disabled', false);
		jQuery('#simpods-cpd-body #next-page-btn').attr('disabled', true );		
        jQuery('#simpods-cpd-body #next-page-btn').attr('data-title', simpods_cpd_language['Why_disabled']);
        jQuery('#simpods-cpd-body #next-page-btn').attr('data-info', simpods_cpd_language['process_all'] );
		jQuery('#simpods-cpd-body #next-page-btn').addClass('simpods-cpd-info-modal-toggle');
		jQuery('#simpods-cpd-body #next-page-btn').attr( 'data-toggle', 'modal' );
		//jQuery('#simpods-cpd-body #next-page-btn').attr( 'data-target', '#simpods-cpd-info-modal' );
	}
		
}
function simpods_cpd_disable_specific_assets( assets ){
 
	var length = assets.length;
	for( var i = 0; i < length; i ++ ){
		if( assets[ i ]['lock'] == true ){
			jQuery( '#asset-box-' + assets[ i ]['id'] + ' input' ).attr('disabled', true );		
			jQuery( '#asset-box-' + assets[ i ]['id'] + ' select' ).attr('disabled', true );		
			//jQuery( '#asset-box-' + assets[ i ]['id'] + ' button' ).attr('disabled', true );	
			jQuery( '#asset-box-' + assets[ i ]['id'] + ' textarea' ).attr('disabled', true );	
			if( assets[ i ]['display_result_button'] == true ){ 
				jQuery( '#asset-box-' + assets[ i ]['id'] + ' .confirm-poll button' ).html('View results');
				jQuery( '#asset-box-' + assets[ i ]['id'] + ' .confirm-poll button' ).addClass('view-poll-results');
				jQuery( '#asset-box-' + assets[ i ]['id'] + ' .confirm-poll button' ).removeClass('submit-poll');
			} else {
				jQuery( '#asset-box-' + assets[ i ]['id'] + ' .confirm-poll button' ).remove();
			}
			//jQuery( '#asset-box-' + assets[ i ]['id'] + ' .confirm-poll button.view-results' ).attr('disabled', false );	
			//jQuery( '#asset-box-' + assets[ i ]['id'] + ' .confirm-poll button.view-results' ).removeAttr('disabled' );	
			//jQuery( '#asset-box-' + assets[ i ]['id'] ).addClass( 'semi-transparent' );
			if( '' != assets[ i ]['feedback'] ){
				feedback_htmls['submitted'] += '<div class="feedback-after-submission">' + assets[ i ]['feedback'] + '</div>';
			}
			jQuery( '#asset-' + assets[ i ]['id'] + '-fb' ).html( feedback_htmls['submitted'] );	
			jQuery( '#asset-' + assets[ i ]['id'] + '-parameters' ).data( 'locked', 1 );	
			
		}			
	}	
}
function simpods_cpd_score_feedback( score_sets ){
	var $ = jQuery;

	if( score_sets.length > 0 ){
		score_sets.forEach( function( score_obj ){
			if ( null !== score_obj.score ){
				
				$( '#asset-content-' + score_obj.id + ' .not-complete' ).addClass( 'hidden' );
				$( '#asset-content-' + score_obj.id + ' .score-info' ).removeClass( 'hidden' );
				$( '#asset-content-' + score_obj.id + ' .score' ).removeClass( 'hidden' );
				$( '#asset-content-' + score_obj.id + ' .score-msgs' ).removeClass( 'hidden' );
				$( '#asset-content-' + score_obj.id + ' .score-num' ).text( score_obj.score );
				$( '#asset-content-' + score_obj.id + ' .total-num' ).text( score_obj.total );
				$( '#asset-content-' + score_obj.id + ' .preset-feedback' ).html( score_obj.feedback );
				if( score_obj.pass === 0 ){
					$( '#asset-content-' + score_obj.id + ' .feedback-icon' ).addClass( 'fa-times red' );
					$( '#asset-content-' + score_obj.id + ' .feedback-icon' ).removeClass( 'fa-check green hidden fa-floppy-o' );				
				}
				if( score_obj.pass === 1 ){
					$( '#asset-content-' + score_obj.id + ' .feedback-icon' ).addClass( 'fa-check green' );
					$( '#asset-content-' + score_obj.id + ' .feedback-icon' ).removeClass( 'fa-times red hidden fa-floppy-o' );
				}	
				if( score_obj.pass === '' ){
					
					$( '#asset-content-' + score_obj.id + ' .feedback-icon' ).addClass( 'fa fa-floppy-o far fa-save' );
					$( '#asset-content-' + score_obj.id + ' .feedback-icon' ).removeClass( 'fa-times red fa-check green hidden' );
				}	
				//$('#asset-' + score_obj.id + '-fb .answer-fb .preset-feedback').html( return_obj[ 'data' ]['feedback'] );

				// $( '#asset-content-' + score_obj.id + ' .score-msg' ).each( function(){
				// 	var $this = $( this );
				// 	if ( 1 == score_obj.show && false == Number.isNaN( score_obj.score ) && score_obj.score >= $this.data( 'min' ) && score_obj.score <= $this.data( 'max' ) ){
				// 		$this.removeClass( 'hidden' );
				// 	} else {
				// 		$this.addClass( 'hidden' );
				// 	}
				// } );
			}
		} );
	}
}

function simpods_cpd_page_feedback(){
	tinyMCE.editors['page-feedback'].on( 'blur', function(){
		if ( this.isDirty() ){
			let data_obj = {
				'action'   : 'cpd_save_feedback',
				'set'      : record_set,
				'security' : cpd_ajax_script.nonce,
				'feedback' : this.getContent()
			},
			$ = jQuery;

			$( '#page-feedback-status .msg' ).empty();
			$( '#page-feedback-status .icon' ).addClass( 'hidden' );
			$( '#page-feedback-status' ).removeClass( 'hidden text-danger text-success' );
			$( '#page-feedback-status .loading' ).removeClass( 'hidden' );

			$.post( {
				url  : cpd_ajax_script.ajaxurl,
				data : data_obj
			} ).done( function( res ){
				$( '#page-feedback-status' ).addClass( 'text-success' );
				$( '#page-feedback-status .icon.save' ).removeClass( 'hidden' );
				$( '#page-feedback-status .msg' ).text( res.data );
			} ).fail( function( jqXHR, textStatus, errorThrown ){
				$( '#page-feedback-status' ).addClass( 'text-danger' );
				$( '#page-feedback-status .icon.fail' ).removeClass( 'hidden' );
				$( '#page-feedback-status .msg' ).text( jqXHR.responseJSON.data );
			} ).always( function(){
				$( '#page-feedback-status .loading' ).addClass( 'hidden' );
			} );
		}
	} );

}

/**
 * Save call back from CPD api
 */
function simpods_cpd_api_callback( id ){
	//jQuery( '#asset-content-' + id ).css('display', 'block');
	jQuery( '#asset-content-' + id +  ' #asset-' + id + '-fb' ).html('<span class="fa fa-floppy-o far fa-save mgr5" aria-hidden="true"></span><span class="answer-fb saved"> <span class="custom-feedback"></span><span class="preset-feedback"> Saved </span></span>');
	//jQuery( '#asset-' + id + '-fb' ).hide();
}	

function simpods_cpd_upload_file( event, handler ){

	var file_obj   	= event.target.files,	    
	    data_obj   	= new FormData(),
	    prefix 		= '#asset-content-' + handler.data( 'id' );
	    
	if(	file_obj.length !== 1 ) {
		return false;
	}   

	// Set action and add standard asset fields to FormData
	data_obj.append( 'action', 'cpd_upload_file' );
	data_obj.append( 'security', cpd_ajax_script.nonce );
	data_obj.append( 'asset', handler.data( 'id' ) );
	data_obj.append( 'set', JSON.stringify( record_set ) ); // Manual JSON encoding required when using FormData

	// Add file to FormData
	jQuery.each( file_obj, function( key, value ){
		data_obj.append( 'file_upload', value );
	} );

	jQuery( prefix + ' .loading' ).css( 'display', 'block' );
	jQuery( '#asset-' + handler.data( 'id' ) + '-load-img' ).removeClass( 'hidden' );
	jQuery( prefix + ' .file-success' ).empty();
	jQuery( prefix + ' .file-error' ).empty();

	jQuery.post( {
		url         : cpd_ajax_script.ajaxurl,
		data        : data_obj,
		processData : false, // Processing will break the file upload
		contentType : false // Keep as multipart/form-data
	} ).done( function( res ){
		jQuery( prefix + ' .file-preview' ).empty();
		
		jQuery( prefix + ' .loading' ).css( 'display', 'none' );
		jQuery( 'asset-' + handler.data( 'id' ) + '-load-img' ).addClass( 'hidden' );

		const date = new Date();
		let time = date.getTime();	// add time to the link to force loading newly uploaded

		var viewable = false;
		if ( res.success ){
			jQuery('#asset-content-' + handler.data( 'id' ) + ' .file_upload_info' ).show();
			// Build preview if an image, video or audio file
			switch( res.data.name.split( '.' )[1] ){
				case 'png':
				case 'jpg':
				case 'jpeg':
				case 'gif':
					jQuery( prefix + ' .file-preview' ).append( '<img src="' + res.data.url + '#' + time + '" />' );
					viewable = true;
					break;
				case 'mp4':
				case 'mov':
				case 'webm':
					jQuery( prefix + ' .file-preview' ).append( '<video src="' + res.data.url + '#' + time + '" controls></video>' );
					viewable = true;
					break;
				case 'mp3':
				case 'm4a':
				case 'm4b':
					jQuery( prefix + ' .file-preview' ).append( '<audio src="' + res.data.url + '#' + time + '" controls></audio>' );
					viewable = true;
					break;
			}


			jQuery.fn.matchHeight._update();

			jQuery('#asset-content-' + handler.data( 'id' ) + ' .btn-secondary' ).prop( 'disabled', false );				
			jQuery('#asset-content-' + handler.data( 'id' ) + ' a.btn-secondary' ).prop( 'disabled', false );
			
			if( viewable === false ){
				jQuery('#asset-content-' + handler.data( 'id' ) + ' .view-uploaded-file' ).prop( 'disabled', true );	
			}				

			if( parseInt( res.data.page_passed ) == 1 ){								
				//jQuery('#next-btn-div').removeClass('hidden');
				//jQuery('#save-btn-div').addClass('hidden');
				jQuery('#complete-txt').removeClass('hidden');	
				//if it is on the course page
				if( typeof jQuery('.module-index-0') != 'undefined' && jQuery('.module-index-0').length != 0 ){
					jQuery('.module-index-0 .module-btn').prop('disabled', false );					
				}

				simpods_cpd_remove_disabled();
			} else {
				//jQuery('#next-btn-div').addClass('hidden');
				//jQuery('#save-btn-div').removeClass('hidden');
				jQuery('#complete-txt').addClass('hidden');
			}	 

			jQuery( prefix + ' .file-upload-name' ).text( res.data.name.split( '.' )[0] );
			jQuery( prefix + ' .file-success' ).text( res.data.info );
			jQuery( prefix + ' .file_upload_info' ).text( res.data.info );
			
			jQuery( prefix + ' .file-url' ).prop( 'href', res.data.url  ).prop( 'type', res.data.type );		
			if( res.data.url != '' ){
				jQuery( prefix + ' .file-url' ).attr('disabled', false );
			}		
		} else {
			jQuery( prefix + ' .file-upload-name' ).empty();
			jQuery( prefix + ' .file-error' ).text( res.data.error );

		}
	} ).fail( function( jqXHR, textStatus, errorThrown ){
		// Clear existing preview/name
		jQuery( prefix + ' .file-preview' ).empty();
		jQuery( prefix + ' .file-upload-name' ).empty();
		jQuery( prefix + ' .loading' ).css( 'display', 'none' );

		// Process error message if any
		if ( 'undefined' != typeof jqXHR.responseJSON ){
			jQuery( prefix + ' .file-error' ).text( jqXHR.responseJSON.data );
		} else {
			jQuery( prefix + ' .file-error' ).text( 'Error communicating with server' );
		}
	} );

}

function simpods_cpd_after_updating_user_answer( return_obj, data_obj, asset_id, input_name, value, option_id, input_type, option_index, callBack ){
	$ = jQuery;
	var feedback_colour 		= 'red';
	var custom_feedback 		= '';
	var custom_feedback_html 	= '';	
	
	var i = 0;		
	
	$( '#' + input_name + '-load-img .loading' ).css('display','none');
	if ( return_obj.success && return_obj.data != false ){
		var feedback_html	   = '';
		var data_length = return_obj.data['asset_done'].length;
		var feedback 	= '';
		// general asset feedback
		for( i = 0; i < data_length; i++) {
			custom_feedback 		= '';						
			
			if( typeof return_obj.data['asset_done'][ i ]['custom_feedback'] != 'undefined' ){
				custom_feedback = return_obj.data['asset_done'][ i ]['custom_feedback'];	
			}
			custom_feedback_html = '<span class="custom-feedback">' + custom_feedback + '</span>';		
							
			feedback_html = '<span class="feedback-icon fas fa-times red" aria-hidden="true"></span> <span class="answer-fb incorrect">' + custom_feedback_html + '<span class="preset-feedback">' + return_obj.data['asset_done'][i]['feedback'] + '</span></span>';	
			//if( $('#asset-content-' + return_obj.data['asset_done'][i]['id'] ).parent().hasClass('video-hotspots') ){
				$('#asset-content-' + return_obj.data['asset_done'][i]['id'] + ' #iv-play-' + return_obj.data['asset_done'][i]['id'] + '').addClass('hidden');		
			//}						
			feedback = return_obj.data['asset_done'][i]['feedback'];
			var icon_for_pass = 'fa-check green';
			
			if( return_obj.data['has_correct'] != true ){
				icon_for_pass = 'fa-save';			
			}
			// return_obj.data['asset_done'][i]['pass']: 0 fail, 1 pass, 2 don't show
			if ( return_obj.data['asset_done'][i]['pass'] > 0  ){						
				feedback_html = '<span class="feedback-icon fas ' + icon_for_pass + '" aria-hidden="true"></span> <span class="answer-fb correct">' + custom_feedback_html + '<span class="preset-feedback">' + return_obj.data['asset_done'][i]['feedback'] + '<span></span>';	

				if( $('#asset-content-' + return_obj.data['asset_done'][i]['id'] ).hasClass('asset-hooked-to-video')  ){ // && 0 == parseInt( pages_passed[0] )	
					$('#asset-content-' + return_obj.data['asset_done'][i]['id'] + ' #iv-play-' + return_obj.data['asset_done'][i]['id'] + '').removeClass('hidden');	

				}

			} else {
				// before multi-answered completed, don't show feedback.
				if( input_type == 'checkbox' || input_type == 'duplicate-select' ){ 
					if ( return_obj.data['asset_done'][i]['pass'] == -1 ){ //not picked enough answers
						feedback_html = '<span class="feedback-icon fa fa-info blue mgr10" aria-hidden="true"></span> <span class="answer-fb">' + custom_feedback_html + '<span class="preset-feedback">' + translation['please_carry_on'] + '</span></span>';									
					} else if ( return_obj.data['asset_done'][i]['pass'] == -2 ){ //when the user knows how many to pick and pick enough answers
						feedback_html = '<span class="feedback-icon fas fa-times red mgr10" aria-hidden="true"></span> <span class="answer-fb">' + custom_feedback_html + '<span class="preset-feedback">' + return_obj.data['asset_done'][i]['feedback'] + '</span></span>';									
					} else {
						if( 1 == $( '#' + input_name + '-fb' ).data( 'progress' ) ){
							feedback_html = '<span class="feedback-icon fa fa-info blue mgr10" aria-hidden="true"></span> <span class="answer-fb">' + custom_feedback_html + '<span class="preset-feedback">' + translation['please_carry_on'] + '</span></span>';	
						}
					}								
				}						
			}
		}	
		var saved_html = '<span class="feedback-icon fas fa-save" aria-hidden="true"></span><span class="answer-fb saved">' + custom_feedback_html + '<span class="preset-feedback"> Saved </span></span>';
		//return_obj.data['has_correct'] == true &&
		if(  return_obj.data['hide_feedback'] == 0 ){
			if( return_obj.data['has_correct'] == true || feedback != '' ){
				$( '#' + input_name + '-fb').html( feedback_html ) ;				
			} else {
				$( '#' + input_name + '-fb').html( saved_html ) ;
			}

		} else {
			$( '#' + input_name + '-fb').html( saved_html ) ;
		}


		if( typeof return_obj.data['poll_type'] != 'undefined' && return_obj.data['poll_type'] != '' ){
			// overwrite above if it is a poll
			if ( 'undefined' == typeof( can_save ) || 1 == can_save['login_required'] || 0 != can_save['current_user_id'] ){ // login required, don't display the submit button
				$( '#' + input_name + '-fb').html( feedback_htmls['ready_to_submit'] ) ;
			}

		}
				
		if( parseInt( return_obj.data['page_passed'] ) == 1 ){								
			$('#complete-txt').removeClass('hidden');
			//if it is on the course page
			if( typeof jQuery('.module-index-0') != 'undefined' && $('.module-index-0').length != 0 ){
				$('.module-index-0 .module-btn').attr('disabled', false );							
			}		
			simpods_cpd_remove_disabled();										
		} else {

			$('#complete-txt').addClass('hidden');

			// hide all the module buttons							
			simpods_cpd_add_disabled();												
					
		}			
		
		if( data_length == 0 ){			
			simpods_cpd_add_disabled();							
		}
		// option feedback
		if( input_type == 'select' ){
			$( '#' + input_name + '-dropdown-fbs').children('.feedback').html('') ;
		}
		//
		if( input_type == 'duplicate-select' ){
			// mult drop down use index and each optoin is correct			
			// assume answered incorrectly first
			if( option_id != 0 && return_obj.data['hide_feedback'] == 0 ){
				feedback_html = '<i class="fa fa-share fa-flip-vertical red cpd-small pdl10" aria-hidden="true"></i> <span class="answer-fb incorrect"><span class="custom-feedback"></span><span class="preset-feedback">Incorrect</span></span>';
				$( '#' + input_name + '-' + option_index + '-fb').html( feedback_html ) ;		
			}
			//var fb_bln	   = false;
			for (var k_int in return_obj.data['option_fb'] ) {
				feedback_html	   = '';
				
				if( k_int == option_index ){
					//feedback_html	   = return_obj.data['option_fb'][ option_id ]['fb'];
					feedback_html	   = return_obj.data['option_fb'][ k_int ]['fb'];
					if( feedback_html == '' || feedback_html == null ){
						feedback_html = 'Correct';
					}
					// if it is the current selection and the answered was picked correctly							
					if( return_obj.data['option_fb'][ option_index ]['correct'] == 1 ){	
						feedback_html = '<i class="fa fa-share fa-flip-vertical green cpd-small pdl10" aria-hidden="true"></i> <span class="answer-fb correct"><span class="custom-feedback"></span><span class="preset-feedback">' + feedback_html + '</span></span>';		
					} else{
						feedback_colour = 'red';
						if( return_obj.data['has_correct'] != true ){
							feedback_colour = 'green';
						}
						feedback_html = '<i class="fa fa-share fa-flip-vertical ' + feedback_colour + ' cpd-small pdl10" aria-hidden="true"></i> <span class="answer-fb incorrect"><span class="custom-feedback"></span><span class="preset-feedback">Incorrect</span></span>';										
					}					
					
					$( '#' + input_name + '-' + option_index + '-fb').html( feedback_html ) ;
				}																					
			}					
			//return_obj.data['has_correct'] == true &&
			if(  return_obj.data['hide_feedback'] == 0  ){
				
				
			} else {
				$( '#' + input_name + '-' + option_index + '-fb').html( '<span class="feedback-icon fa fa-floppy-o far fa-save  cpd-small pdl10" aria-hidden="true"></span><span class="answer-fb saved"><span class="custom-feedback"></span><span class="preset-feedback"> Saved </span></span>' ) ;
			}					
							
		} else {
			var option_feedback_html	   = '';
			for (var k_int in return_obj.data['option_fb'] ) {
				option_feedback_html	   = '';
			
				if( typeof return_obj.data['option_fb'][ option_id ] != 'undefined' && return_obj.data['hide_feedback'] == 0 ){
					
					option_feedback_html	   = return_obj.data['option_fb'][ option_id ]['fb'];

					if( option_feedback_html != '' && option_feedback_html != null ){
						if( return_obj.data['option_fb'][ option_id ]['correct'] == 1 ){
							option_feedback_html = '<i class="fa fa-share fa-flip-vertical green cpd-small pdl10" aria-hidden="true"></i> <span class="answer-fb correct"><span class="custom-feedback"></span><span class="preset-feedback">' + option_feedback_html + '</span></span>';		
						} else{
							feedback_colour = 'red';
							if( return_obj.data['has_correct'] != true ){
								feedback_colour = 'green';
							}										
							option_feedback_html = '<i class="fa fa-share fa-flip-vertical ' + feedback_colour + ' cpd-small pdl10" aria-hidden="true"></i> <span class="answer-fb incorrect"><span class="custom-feedback"></span><span class="preset-feedback">' + option_feedback_html + '</span></span>';	
							// multi answer
							if( input_type == 'checkbox' ){
								//$( '#' + input_name + '-' + option_id + '-fb').parent().children('input[type="checkbox"]').prop('checked', false ) ;
								
								var ckid_str	= $( '#' + input_name + '-' + option_id + '-fb').parent().children('input[type="checkbox"]').attr('id')	;
								//simpods_cpd_toggle_inputs( true, ckid_str );
								value = '';
								$.each( $('input[name="' + input_name + '"]:checked'), function(){
									value = value + $( this ). val() + ',';
									
								})			
								if( value != '' ){
									value = value.substr( 0, value.length - 1 );	
								}															
								//with_options( input_name, value, option_id, input_type );
								//console.log( value );
							}
						}
						$( '#' + input_name + '-' + option_id + '-fb').removeClass('hidden d-none');
						$( '#' + input_name + '-' + option_id + '-fb').html( option_feedback_html ) ;
					
					}
				}
								
			}
		}
		simpods_cpd_play_sound( return_obj.data['sound'] );

		if( callBack ) {						
			callBack( asset_id, return_obj.data );		  
		}

		simpods_cpd_score_feedback( return_obj.data.score_sets );
		simpods_cpd_disable_specific_assets( return_obj.data.asset_done );
		
		if( return_obj.data['jump_to'] != '' ){
			
			if( simpods_cpd_is_url_valid( return_obj.data['jump_to'] )  ){
				if( return_obj.data['asset_type'] == 'Certificate' ){
					//This will open it on the same tab if the pop-up is blocked. || window.location.replace( return_obj.data['jump_to'] )
					window.open( return_obj.data['jump_to'] , '_blank') ; //let people unblock pop-up as the back button dosen't work after replacing the url	
				} else {
					// multi-choice
					window.location.replace( return_obj.data['jump_to'] );
				}
				
			} else {
				simpods_cpd_scroll_to( return_obj.data['jump_to'] );
				$( '#asset-box-' + asset_id ).addClass('hidden');

				$( '#' + return_obj.data['jump_to'] ).removeClass('hidden');
			
				$('#asset-content-' + data_obj['decision_tree'] + ' .decision-tree-completed-flag').addClass('opacity-30');
			}
		} else {
			
			if(	'' != data_obj['decision_tree'] && ! isNaN( data_obj['decision_tree'] ) ){ 	// isNaN take a empty string as 0											
				// No assets to jump to, pass in 'pass' so all unanswered assets in the tree can be marked as "_auto_passed" and the relevant JavaScript code like activate/disable inputs can be triggered
				simpods_cpd_toggle_inputs( true );
				simpods_cpd_pass_asset( data_obj['decision_tree'], '_pass', simpods_cpd_save_call_back );				
			}
		}

		if( 'Decision Tree' == return_obj.data['asset_type'] ){
			$('#asset-content-' + asset_id + ' .decision-tree-completed-flag').removeClass('opacity-30');
		}
		if( '' != data_obj['decision_tree'] ){
			$( '#decision-tree-branch-' + data_obj['decision_tree'] + '-' + asset_id ).addClass('light-on');
		}	

	} 
	$.fn.matchHeight._update();
			
}
/**
 * save data to local storage when login is not required
 */ 
function simpods_cpd_save_to_local_storage( args, data_after_saving ){
	
	if ( 'undefined' == typeof( can_save ) || 1 == can_save['login_required'] || 0 != can_save['current_user_id'] ){ // login required, don't save to the local storage		
		return;
	}
	if (typeof(Storage) === 'undefined' ) {
	  	return;   // Sorry! No Web Storage support..
	} 
	
	if ( sessionStorage.simpods_cpd_asset_answers ) {
	  	var simpods_cpd_asset_answers = JSON.parse( sessionStorage.simpods_cpd_asset_answers );
	} else {		
		
	  	var simpods_cpd_asset_answers = {};
	} 
	if( args['action'] == 'update_asset_user_answer' ){
		
		for( let key in args ) {
		    if( key.indexOf('asset-') == 0 ){
		    	let for_asset 	= key.split('-');
		    	let asset_id 	= for_asset[1];
		    	if( typeof args[ key ] == 'object' ){
		    		for( let asset_key in args[ key ] ) {
		    			args[ key ][ asset_key ] = simpods_cpd_esc_string( args[ key ][ asset_key ].toString() );
		    		}
		    		var asset_value	= args[ key ] ;
		    		
		    	} else {		    		
		    		var asset_value	= simpods_cpd_esc_string( args[ key ].toString() ) ;
		    	}
		    	let answer 	= {
		    		id: asset_id,
		    		answer: asset_value, 
		    		pass: 0, 
		    		score: 0, 
		    		accuracy: 0
		    	};
		    	
		    	if( data_after_saving['data'] != false && data_after_saving['data']['asset_done'].length != 0 ){
		    		for( let asset_done_key in data_after_saving['data']['asset_done'] ){
		    			if( data_after_saving['data']['asset_done'][ asset_done_key ]['id'] == asset_id ){
		    				answer.pass 	= data_after_saving['data']['asset_done'][ asset_done_key ]['pass'];
		    				answer.score 	= data_after_saving['data']['asset_done'][ asset_done_key ]['score'];
		    				answer.accuracy = data_after_saving['data']['asset_done'][ asset_done_key ]['accuracy'];
		    			}
		    		}

		    	}
		        simpods_cpd_asset_answers[ args['set']['course_id'] + '_'  + args['set']['module_id'] + '_'  + args['set']['page_id'] + '_' + asset_id ] =  answer; 

		        break;
		    }
		}		
	}
	
	sessionStorage.setItem( 'simpods_cpd_asset_answers', JSON.stringify(  simpods_cpd_asset_answers ) );
}
/**
 * load the answer
 * 
 * @param args['set'] = {
 * 	'course_id': 0,
 * 	'module_id': 0,
 * 	'page_id': 0,
 * }
 * @param args['asset_ids'] = [1,2,3,4]
 */ 
function simpods_cpd_load_local_storage( args ){
	
	if ( 'undefined' == typeof( can_save ) || 1 == can_save['login_required'] || 0 != can_save['current_user_id'] ){ // login required, don't save to the local storage		
		return '';
	}
	if ( typeof( Storage ) === 'undefined' ) {
	  	return;   // Sorry! No Web Storage support..
	} 
	
	if ( sessionStorage.simpods_cpd_asset_answers ) {
	  	var simpods_cpd_asset_answers = JSON.parse( sessionStorage.simpods_cpd_asset_answers );
	} else {		
		
	  	var simpods_cpd_asset_answers = {};
	} 

	if( typeof( args['asset_ids'] ) != 'object' || typeof( args['set'] ) != 'object' || args['asset_ids'].length == 0 ){
		return {};
	}
	
	let answers = {};
	// escape the data in case the data are altered.
	let i 		= 0;
	let scope 	= args['asset_ids'].length; 
	for( i = 0; i < scope; i ++ ){
		if( ! isNaN( args['asset_ids'][ i ] ) ){
			let asset_id = args['asset_ids'][ i ];
		    if( typeof( simpods_cpd_asset_answers[ args['set']['course_id'] + '_'  + args['set']['module_id'] + '_'  + args['set']['page_id'] + '_' + asset_id ] ) != 'undefined' ){
		    	let asset_value 	= simpods_cpd_asset_answers[ args['set']['course_id'] + '_'  + args['set']['module_id'] + '_'  + args['set']['page_id'] + '_' + asset_id ];
		    	//purify json string
		    	
		    	// asset_value = asset_value.replace(/\\/g, ''); //remove back slashes
		    	// asset_value = asset_value.replace(/"{/g, '{'); 
		    	// asset_value = asset_value.replace(/}"/g, '}'); 
		    	// asset_value = asset_value.replace(/"\[/g, '['); 
		    	// asset_value = asset_value.replace(/\]"/g, ']'); 
	    		
	    		 //decode		    
		    	
		    	if( typeof asset_value == 'object' || typeof asset_value == 'array' ){
		    		for( let asset_key in asset_value ) {	
		    			if( typeof asset_value[ asset_key ] == 'object' || typeof asset_value[ asset_key ] == 'array' ){
		    				for( let sub_key in asset_value[ asset_key ] ) {	
		    					asset_value[ asset_key ][ sub_key ] = simpods_cpd_esc_string( asset_value[ asset_key ][ sub_key ] );
		    				}		    				
			    		} else {
			    			if( isNaN( asset_value[ asset_key ] ) && ( asset_value[ asset_key ].indexOf( '["' ) != - 1 || asset_value[ asset_key ].indexOf( '{"' ) != - 1 ) ){
			    				
			    				asset_value[ asset_key ] = JSON.parse( asset_value[ asset_key ] );
			    				for( let sub_key in asset_value[ asset_key ] ) {	
			    					asset_value[ asset_key ][ sub_key ] = simpods_cpd_esc_string( asset_value[ asset_key ][ sub_key ] );
			    				}
			    			} else {
			    				asset_value[ asset_key ] = simpods_cpd_esc_string( asset_value[ asset_key ] );
			    			}
			    		}
		    		}
		    	} else {
		    		asset_value = simpods_cpd_esc_string( asset_value );
		    	}
		    	answers['asset-' + asset_id ] = asset_value;
		    }
		}
	}
 	console.log()
	return answers;
}
